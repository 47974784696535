<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.create_hospitalization") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="quitEmpty()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="center__block_form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        class="aticler_m"
        size="small"
      >
        <el-row :gutter="25">
          <el-col :sm="12">
            <el-form-item :label="$t('message.number_card')">
              <el-input v-model="form.number" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :sm="12">
            <el-form-item
              :label="$t('message.date_hospitalization')"
              prop="date_gos"
            >
              <el-date-picker
                type="date"
                placeholder=".. / .. / ...."
                v-model="form.date_gos"
                style="width: 100%"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :sm="12">
            <el-form-item
              :label="$t('message.diagnos')"
              class="ik_label"
            >
              <el-input v-model="form.diagnos" placeholder="......"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :sm="12">
            <el-form-item :label="$t('message.doctor')" prop="doctor_id">
              <users
                :placeholder="$t('message.doctor')"
                v-model="form.doctor_id"
                :doctor_id="form.doctor_id"
              ></users>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :sm="12">
            <el-form-item :label="$t('message.branch')" prop="branch_id">
              <branches
                v-model="form.branch_id"
                :branch_id="form.branch_id"
              ></branches>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :sm="12">
            <el-form-item :label="$t('message.hospitalization_type')">
              <typeHospitalizations
                v-model="form.type_hospitalization_id"
                :type_hospitalization_id="form.type_hospitalization_id"
              ></typeHospitalizations>
            </el-form-item>
          </el-col>

          <el-col :sm="12">
            <el-form-item :label="$t('message.purpose_of_hospitalization')">
              <purposeHospitalizations
                v-model="form.purpose_hospitalization_id"
                :purpose_hospitalization_id="form.purpose_hospitalization_id"
              ></purposeHospitalizations>
            </el-form-item>
          </el-col>

          <el-col :sm="12">
            <el-form-item :label="$t('message.blood')">
              <el-select v-model="form.blood" placeholder=".....">
                <el-option label="O(I) Rh−" value="first_minus"></el-option>
                <el-option label="O(I) Rh+" value="first_plus"></el-option>
                <el-option label="A(II) Rh−" value="second_minus"></el-option>
                <el-option label="A(II) Rh+" value="second_plus"></el-option>
                <el-option label="B(III) Rh−" value="third_minus"></el-option>
                <el-option label="B(III) Rh−" value="third_plus"></el-option>
                <el-option label="AB(IV) Rh−" value="fourth_minus"></el-option>
                <el-option label="AB(IV) Rh+" value="fourth_plus"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.puls')" class="ik_label">
              <el-row>
                <el-col :span="11">
                  <el-input
                    v-model="form.impuls_one"
                    placeholder="......"
                  ></el-input>
                </el-col>
                <el-col class="line text-center" :span="2">-</el-col>
                <el-col :span="11">
                  <el-input
                    v-model="form.impuls_two"
                    placeholder="......"
                  ></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.temperature')">
              <el-input
                v-model="form.teperatura"
                placeholder="......"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.condition')">
              <injuryStatuses
                v-model="form.status_id"
                :placeholder="$t('message.conditions')"
                :status_id="form.status_id"
              ></injuryStatuses>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.weight')">
              <el-input v-model="form.weight" placeholder="......"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.height')">
              <el-input v-model="form.height" placeholder="......"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.type_of_injury')">
              <injuryTypes
                :placeholder="$t('message.type_of_injury')"
                v-model="form.injury_type_id"
                :injury_type_id="form.injury_type_id"
              ></injuryTypes>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.allergy')">
              <el-input type="textarea" v-model="form.allergy"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.date_injury')">
              <el-date-picker
                type="date"
                placeholder=".. / .. / ...."
                v-model="form.date_injury"
                style="width: 100%"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item
              :label="$t('message.date_register')"
              prop="date_register"
            >
              <el-date-picker
                type="date"
                placeholder=".. / .. / ...."
                v-model="form.date_register"
                style="width: 100%"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :picker-options="datePickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <el-tabs type="border-card" class="mt-2">
              <el-tab-pane :label="$t('message.direction')">
                <el-row :gutter="25">
                  <el-col :sm="8">
                    <div class="user-kod">
                      <div class="title_cet">
                        {{ $t("message.partner_clinic") }}
                      </div>
                      <div class="user-kod-top m-center">
                        <el-select
                          v-model="form.partner_clinic_id"
                          class="w-reset m-p-1"
                          filterable
                          clearable
                          size="mini"
                          @change="filterDepartment"
                          :placeholder="$t('message.partner_clinic')"
                        >
                          <el-option
                            v-for="item in partnerClinics"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <!-- <el-button
                        class="add_pats"
                        @click="drawerPartner = true"
                        >{{ $t("message.add_new_partner_doctor") }}</el-button
                      > -->
                    </div>
                  </el-col>
                  <el-col :sm="8">
                    <div class="user-kod m-center m-w-100 m-flex-column">
                      <div class="title_cet">
                        {{ $t("message.department") }}
                      </div>
                      <div class="user-kod-top">
                        <el-select
                          size="mini"
                          v-model="form.department_id"
                          filterable
                          clearable
                          :placeholder="$t('message.department')"
                        >
                          <el-option
                            v-for="item in departments"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <el-button
                        class="add_pats"
                        @click="drawerDepartment = true"
                        >{{ $t("message.add_new_department") }}</el-button
                      >
                    </div>
                  </el-col>
                  <el-col :sm="8">
                    <div class="user-kod m-center m-flex-column m-w-100">
                      <div class="title_cet">
                        {{ $t("message.partner_doctor") }}
                      </div>
                      <div class="user-kod-top">
                        <partnerDoctor
                          v-model="form.partner_doctor_id"
                          :partner_clinic_id="form.partner_clinic_id"
                          :partner_doctor_id="form.partner_doctor_id"
                          size="mini"
                        ></partnerDoctor>
                      </div>
                      <el-button
                        class="add_pats"
                        @click="drawerPartner = true"
                        >{{ $t("message.add_new_partner_doctor") }}</el-button
                      >
                    </div>
                  </el-col>
                  <!-- end col -->
                </el-row>
                <!-- end el-row -->
              </el-tab-pane>
              <!-- end  el-tab-pane-->

              <el-tab-pane :label="$t('message.add_to_room')">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.class") }}:</div>
                      <div class="name_h" v-text="classs"></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                  <el-col :span="8">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.corps") }}:</div>
                      <div class="name_h" v-text="corps"></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->

                  <el-col :span="8">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.floor") }}:</div>
                      <div class="name_h" v-text="floor"></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                  <el-col :span="8">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.room") }}:</div>
                      <div class="name_h" v-text="room"></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                  <el-col :span="8">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.bunk") }}:</div>
                      <div class="name_h" v-text="bunkname"></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->

                  <el-col :span="8">
                    <div class="userPasten_info">
                      <div class="title_pp">
                        {{ $t("message.arrival_date") }}:
                      </div>
                      <div class="name_h" v-text="start"></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                  <el-col :span="8">
                    <div class="userPasten_info">
                      <div class="title_pp">
                        {{ $t("message.departure_date") }}:
                      </div>
                      <div class="name_h" v-text="end"></div>
                    </div>
                  </el-col>
                  <!-- end el-col -->
                </el-row>

                <el-col :span="24" class="m-center">
                  <el-button
                    icon="el-icon-edit"
                    @click="drawer.room.status = true"
                    type="primary"
                    round
                    class="mb-1 mb-2"
                    >{{ $t("message.add_bunk") }}</el-button
                  >
                </el-col>
              </el-tab-pane>
              <!-- end  el-tab-pane-->

              <el-tab-pane :label="$t('message.pockets')">
                <div style="width: 100%" class="user-kod d-flex flex-column">
                  <el-row class="mb-3">
                    <el-col :sm="8">
                      <div class="title_cet m-2">
                        {{ $t("message.pockets") }}
                      </div>
                      <div class="user-kod-top">
                        <el-select
                          v-model="form.pocket_id"
                          filterable
                          clearable
                          size="mini"
                          @change="filterPocket"
                          :placeholder="$t('message.pockets')"
                        >
                          <el-option
                            v-for="item in pockets"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </el-col>
                  </el-row>
                  <!-- {{pockets}} -->
                  <el-row v-if="pocket">
                    <el-col :sm="24">
                      <div class="userPasten_info m-flex-column">
                        <div class="title_pp">
                          {{ $t("message.description") }}:
                        </div>
                        <!-- <div class="name_h">{{ pocket.description }}</div> -->
                        <div class="name_h" v-html="pocket.description"></div>
                      </div>
                    </el-col>

                    <el-col :sm="24" v-if="!room_id">
                      <div class="userPasten_info m-flex-column">
                        <div class="title_pp">
                          {{ $t("message.price_one_day_without_room_price") }}:
                        </div>
                        <div class="name_h">{{ pocket.price | formatMoney}}</div>
                      </div>
                    </el-col>

                    <el-col :sm="24" v-if="!room_id">
                      <div class="userPasten_info m-flex-column">
                        <div class="title_pp">
                          {{ $t("message.price_one_day_min_room_price") }}:
                        </div>
                        <div class="name_h">
                          {{
                            lowPriceFilter(pocket.pocket_rooms, pocket.price) | formatMoney
                          }}
                        </div>
                      </div>
                    </el-col>

                    <el-col :sm="24" v-if="room_id">
                      <div class="userPasten_info m-flex-column">
                        <div class="title_pp">
                          {{ $t("message.price_one_day_with_room_price") }}:
                        </div>
                        <div class="name_h">
                          {{
                            roomPriceFilter(
                              pocket.pocket_rooms,
                              room_id,
                              pocket.price
                            )
                          }}
                        </div>
                      </div>
                    </el-col>
                    <el-col :sm="24" v-if="room_id">
                      <div class="userPasten_info m-flex-column">
                        <div class="title_pp">
                          {{
                            $t("message.price_with_room_price", {
                              days: numberOfDays,
                            })
                          }}:
                        </div>
                        <div class="name_h">
                          {{
                            totalPriceFilter(
                              pocket.pocket_rooms,
                              room_id,
                              numberOfDays,
                              pocket.price
                            )
                          }}
                        </div>
                      </div>
                    </el-col>

                    <!-- necci kun yotishi -->
                    <!-- {{ numberOfDays }} -->
                  </el-row>
                  <!-- <el-button
                        class="add_pats"
                        @click="drawerPartner = true"
                        >{{ $t("message.add_new_partner_doctor") }}</el-button
                      > -->
                </div>
              </el-tab-pane>
              <!-- end  el-tab-pane-->
            </el-tabs>
            <!-- end el-tabs -->
          </el-col>
          <!-- end col -->
        </el-row>
        <!-- end el-row -->
      </el-form>
    </div>

    <el-drawer
      title="I am the title"
      :append-to-body="true"
      :visible.sync="drawer.room.status"
      size="100%"
      :wrapperClosable="false"
      :ref="drawer.room.name"
      :with-header="false"
      @opened="drawerOpened(drawer.room.component)"
      @closed="drawerClosed(drawer.room.component)"
    >
      <ModalRoom
        :ref="drawer.room.component"
        :patient_name="selected.first_name + ' ' + selected.surname"
        :drawer-name="drawer.room.name"
      />
    </el-drawer>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerPartner"
      size="40%"
      :wrapperClosable="false"
    >
      <AddPartnerDoctor
        @c-close="closeDrawerAddPartnerDoctor"
      ></AddPartnerDoctor>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerDepartment"
      direction="rtl"
      size="50%"
      :wrapperClosable="false"
    >
      <CreateDepartment
        @c-close="closeDrawerAddPartnerDoctor"
      ></CreateDepartment>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import drawerChild from "@/utils/mixins/drawer-child";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import branches from "@/components/selects/branchWarehouse";
import injuryTypes from "@/components/selects/injuryType";
import injuryStatuses from "@/components/selects/injuryStatus";
import typeHospitalizations from "@/components/selects/typeHospitalization";
import purposeHospitalizations from "@/components/selects/purposeHospitalization";
import users from "@/components/selects/user_list";
import partnerDoctor from "@/components/inventory-select/partner-doctor";
import AddPartnerDoctor from "@/views/scheduler/add-partner-doctor";
import CreateDepartment from "@/views/scheduler/add-department";
import ModalRoom from "./modalRoom";

export default {
  props: ["selected"],
  mixins: [form, drawerChild, drawer],
  components: {
    ModalRoom,
    CreateDepartment,
    branches,
    injuryTypes,
    purposeHospitalizations,
    users,
    typeHospitalizations,
    injuryStatuses,
    partnerDoctor,
    AddPartnerDoctor,
  },
  data() {
    return {
      drawerPartner: false,
      corps: "",
      numberOfDays: 0,
      floor: "",
      classs: "",
      room: "",
      room_id: "",
      pocket: null,
      bunkname: "",
      start: "",
      end: "",
      drawerDepartment: false,
      drawer: {
        room: {
          status: false,
          name: "room",
          component: "roomComponent",
        },
      },
      form: {
        date_register: new Date(),
        pocketPrice: null,
        lowPrice: null,
        totalPrice: null,
        roomPrice: null,
      },
      datePickerOptions: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },

  created() {
    this.form.date_register = new Date();
  },
  computed: {
    filtered_pockets: function () {
      if (this.state_id) {
        return this.pockets.filter((o) => {
          return o.pocket_rooms == o.pocket_rooms.find((room) => room.id);
        });
      } else {
        return this.regions;
      }
    },
    ...mapGetters({
      rules: "patientHistories/rules",
      model: "patientHistories/model",
      columns: "patientHistories/columns",
      lastId: "patientHistories/lastId",
      pockets: "pocketsName/list",
      departments: "department/list",
      partnerClinics: "partnerClinic/list",
      selected_partner_doctor: "partnerDoctor/selected_partner_doctor",
      bunk: "schedulesCabinets/bunk",
      partnerDoctors: "partnerDoctor/list",
    }),
  },
  mounted() {
    var order_query = { column: "name", order: "asc" };

    if (this.partnerDoctors && this.partnerDoctors.length === 0)
      this.partnerDoctorsList(order_query);

    if (this.departments && this.departments.length === 0)
      this.loadDepartments(order_query);

    if (this.partnerClinics && this.partnerClinics.length === 0)
      this.partnerClinicsList(order_query);

    if (this.pockets && this.pockets.length === 0) this.updateList();
  },
  methods: {
    ...mapActions({
      updateList: "pocketsName/index",
      save: "patientHistories/store",
      getLastId: "patientHistories/getLastId",
      partnerClinicsList: "partnerClinic/index",
      bunkEmpty: "schedulesCabinets/empty",
      partnerDoctorsList: "partnerDoctor/inventory",
      loadDepartments: "department/index",
      empty: "schedulesCabinets/empty",
    }),
    lowPriceFilter(val, price) {
      return (
        parseFloat(Math.min(...val.map((item) => item.price))) +
        parseFloat(price)
      );
    },
    roomPriceFilter(val, id, price) {
      return val.find((el) => el.room_id == id)
        ? parseFloat(val.find((el) => el.room_id == id).price) +
            parseFloat(price)
        : "";
    },
    totalPriceFilter(val, id, days, price) {
      return val.find((el) => el.room_id == id)
        ? (parseFloat(val.find((el) => el.room_id == id).price) +
            parseFloat(price)) *
            days
        : "";
    },
    filterPocket(val) {
      this.pocket = this.pockets.find((element) => element.id == val);
    },
    filterDepartment(val) {
      const query = { partner_clinic_id: val };
      this.loadDepartments(query).then((res) => {
        if (!res.data.departments.data.length) this.form.department_id = null;
      });
    },
    afterOpened() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.form.hospital_patient_id = this.selected.id;
      if (!this.lastId) {
        this.getLastId().then((res) => {
          this.form.number = this.getFormatted(res.data.last_id);
        });
      } else {
        this.form.number = this.getFormatted(this.lastId);
      }
      this.form.date_register = new Date();
    },
    getFormatted(num) {
      if (num < 10) {
        return "000 000 00" + num;
      } else if (num >= 10 && num < 100) {
        return "000 000 0" + num;
      } else if (num >= 100 && num < 1000) {
        return "000 000 " + num;
      } else if (num >= 1000 && num < 10000) {
        return "000 00" + new Intl.NumberFormat().format(num);
      } else if (num >= 10000 && num < 100000) {
        return "000 0" + new Intl.NumberFormat().format(num);
      } else if (num >= 100000 && num < 1000000) {
        return "000 " + new Intl.NumberFormat().format(num);
      } else if (num >= 1000000 && num < 10000000) {
        return "00" + new Intl.NumberFormat().format(num);
      } else if (num >= 10000000 && num < 100000000) {
        return "0" + new Intl.NumberFormat().format(num);
      } else if (num >= 100000000 && num < 1000000000) {
        return new Intl.NumberFormat().format(num);
      }
      return num;
    },
    afterClosed() {
      this.empty();
      this.bunkEmpty();
    },
    quitEmpty() {
      this.close();
      this.bunkEmpty();
      if (this.$refs["roomComponent"]) {
        this.$refs["roomComponent"].closeDel();
      }
    },
    bunkEmpty() {
      this.classs = "";
      this.corps = "";
      this.floor = "";
      this.room = "";
      this.bunkname = "";
      this.start = "";
      this.end = "";
      this.form.patient_schedule_id = "";
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (this.pocket && !this.form.patient_schedule_id) {
          return this.$notify({
            title: "Внимание",
            type: "warning",
            offset: 130,
            message: "Выберите комнату, пожалуйста",
          });
        }

        if (valid) {
          this.loadingButton = true;

          if (this.pocket) {
            this.form.pocketPrice = this.pocket.price;
            this.form.totalPrice = this.totalPriceFilter(
              this.pocket.pocket_rooms,
              this.room_id,
              this.numberOfDays,
              this.pocket.price
            );
            this.form.roomPrice = this.roomPriceFilter(
              this.pocket.pocket_rooms,
              this.room_id,
              this.pocket.price
            );
            this.form.lowPrice = this.lowPriceFilter(
              this.pocket.pocket_rooms,
              this.pocket.price
            );
          }

          this.save(this.form)
            .then((res) => {
              this.bunkEmpty();
              this.loadingButton = false;
              this.$notify({
                title: "Успешно",
                type: "success",
                offset: 130,
                message: res.message,
              });
              this.form = {
                date_register: new Date(),
              };
              this.parent().listChanged();
              if (close == true) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    loadBunk() {
      this.classs = this.bunk.bunk ? this.bunk.bunk.classs.name : "";
      this.corps = this.bunk.bunk ? this.bunk.bunk.corps.name : "";
      this.floor = this.bunk.bunk ? this.bunk.bunk.floor.name : "";
      this.room = this.bunk.bunk ? this.bunk.bunk.hospitalRoom.name : "";
      this.room_id = this.bunk.bunk ? this.bunk.bunk.hospitalRoom.id : "";
      this.bunkname = this.bunk.bunk ? this.bunk.bunk.name : "";
      this.start = this.bunk.start;
      this.end = this.bunk.end;
      this.form.patient_schedule_id = this.bunk.id;
      const start = new Date(this.start);
      const end = new Date(this.end);
      const timeDiff = Math.abs(end - start);
      this.numberOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async closeDrawerAddPartnerDoctor(obj) {
      if (obj.reload) {
        await this.partnerDoctorsList().then((res) => {
          this.form.partner_doctor_id = this.selected_partner_doctor.id;
        });
      }
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>
