<template>
  <div class="patient__History">
    <div class="mt10 mb-3">
      <el-breadcrumb class="mt-3" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/hospital' }">{{
          $t("message.hospital")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          $t("message.patient_histories")
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div class="
            col-9
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
            w-reset
            m-d-flex
          ">
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.patient_histories") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input class="ml-3" size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
              v-model="filterForm.search" clearable></el-input>
          </div>
        </div>
        <div class="
            col-3
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
            w-reset
          ">
          <!-- <export-excel  class="btn excel_btn  mr-2" :data="list"
                        worksheet="Пользователи" name="Пользователи.xls">
                        <el-button size="mini" icon="el-icon-document-delete"> Excel </el-button>
                </export-excel>-->
          <crm-column-settings :columns="columns" :modelName="'patientHistory'"
            @c-change="updateColumn"></crm-column-settings>
        </div>
      </div>
      <table class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white" v-loading="loadingData">
        <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
        <thead>
          <tr>
            <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.first_name" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.surname" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.patronymic" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.passport_number" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.state" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.city" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.region" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.dob" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.code" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.gender" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.doctor" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.hospitalization_type" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.hospitalization_created_at" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.created_at" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.updated_at" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.button"></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input clearable size="mini" v-model="filterForm.id" class="id_input"
                :placeholder="columns.id.title"></el-input>
            </th>
            <th v-if="columns.first_name.show">
              <el-input clearable size="mini" v-model="filterForm.first_name"
                :placeholder="columns.first_name.title"></el-input>
            </th>
            <th v-if="columns.surname.show">
              <el-input clearable size="mini" v-model="filterForm.surname"
                :placeholder="columns.surname.title"></el-input>
            </th>
            <th v-if="columns.patronymic.show">
              <el-input clearable size="mini" v-model="filterForm.patronymic"
                :placeholder="columns.patronymic.title"></el-input>
            </th>
            <th v-if="columns.passport_number.show">
              <el-input clearable size="mini" v-model="filterForm.passport_number"
                :placeholder="columns.passport_number.title"></el-input>
            </th>
            <th v-if="columns.state.show">
              <el-select filterable clearable :placeholder="columns.state.title" size="mini"
                v-model="filterForm.state_id">
                <el-option v-for="item in states" :key="item.name + item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </th>
            <th v-if="columns.city.show">
              <el-select filterable clearable :placeholder="columns.city.title" size="mini" v-model="filterForm.city_id">
                <el-option v-for="item in cities" :key="item.name + item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </th>
            <th v-if="columns.region.show">
              <el-select filterable clearable :placeholder="columns.region.title" size="mini"
                v-model="filterForm.region_id">
                <el-option v-for="item in regions" :key="item.name + item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </th>
            <th v-if="columns.dob.show">
<!--              <el-date-picker :placeholder="columns.dob.title" v-model="filterForm.born_date" :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'" size="mini"></el-date-picker>-->
            </th>
            <th v-if="columns.code.show">
              <el-input clearable size="mini" v-model="filterForm.code" :placeholder="columns.code.title"
                v-mask="'## ### ### ###'"></el-input>
            </th>
            <th v-if="columns.gender.show">
              <el-select filterable clearable :placeholder="columns.gender.title" size="mini" v-model="filterForm.gender">
                <el-option :label="$t('message.male')" value="man"></el-option>
                <el-option :label="$t('message.female')" value="woman"></el-option>
              </el-select>
            </th>
            <th v-if="columns.doctor.show">
              <el-input clearable size="mini" v-model="filterForm.doctor"
                        :placeholder="columns.doctor.title"></el-input>
            </th>
            <th v-if="columns.hospitalization_type.show">
              <el-select filterable clearable :placeholder="columns.hospitalization_type.title" size="mini" v-model="filterForm.branch_id">
                <el-option :label="$t('message.daily_hospital')" value="1"></el-option>
                <el-option :label="$t('message.hospital')" value="11"></el-option>
              </el-select>
            </th>
            <th v-if="columns.hospitalization_created_at.show">
              <el-date-picker :placeholder="columns.hospitalization_created_at.title" v-model="filterForm.hospital_created_at"
                              :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" size="mini"></el-date-picker>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker :placeholder="columns.created_at.title" v-model="filterForm.created_at"
                :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" size="mini"></el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker :placeholder="columns.updated_at.title" v-model="filterForm.updated_at"
                :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" size="mini"></el-date-picker>
            </th>
            <th v-if="columns.button.show"></th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="user in list" :key="user.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ user.id }}</td>
            <td v-if="columns.first_name.show">{{ user.first_name }}</td>
            <td v-if="columns.surname.show">{{ user.surname }}</td>
            <td v-if="columns.patronymic.show">{{ user.patronymic }}</td>
            <td v-if="columns.passport_number.show">
              {{ user.passport_number }}
            </td>
            <td v-if="columns.state.show">{{ user.state ? user.state.name : '' }}</td>
            <td v-if="columns.city.show">
              {{ user.city ? user.city.name : "" }}
            </td>
            <td v-if="columns.region.show">
              {{ user.region ? user.region.name : "" }}
            </td>
            <td v-if="columns.dob.show">{{ user.born_date }}</td>
            <td v-if="columns.code.show">{{ user.code }}</td>
            <td v-if="columns.gender.show">
              <template v-if="user.gender === 'man'">{{
                $t("message.male")
              }}</template>
              <template v-else>{{ $t("message.female") }}</template>
            </td>
            <td v-if="columns.doctor.show">{{ user.doctor }}</td>
            <td v-if="columns.hospitalization_type.show">
              <template v-if="user.branch === 1">{{
                  $t("message.daily_hospital")
                }}</template>
              <template v-else-if="user.branch === 11">{{ $t("message.hospital") }}</template>
            </td>
            <td v-if="columns.hospitalization_created_at.show">{{ user.hospitalization_created_at }}</td>
            <td v-if="columns.created_at.show">{{ user.created_at }}</td>
            <td v-if="columns.updated_at.show">{{ user.updated_at }}</td>
            <td v-if="columns.button.show">
              <el-row class="btns_tables">
                <el-button size="mini" v-can="'hospitalPatients.buttons'" type="primary"
                  @click="openHospitalization(user)" round>{{ $t("message.hospitalization") }}</el-button>
                <template v-if="user.patientHistories == ''">
                  <el-button size="mini" type="success" v-can="'hospitalPatients.buttons'" :disabled="true" round>{{
                    $t("message.open") }}</el-button>
                </template>
                <template v-else>
                  <router-link v-can="'hospitalPatients.buttons'" :to="{ name: 'patientHome', params: { id: user.id } }">
                    <el-button size="mini" type="success" round>{{
                      $t("message.open")
                    }}</el-button>
                  </router-link>
                </template>
              </el-row>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer size="100%" :wrapperClosable="false" :visible.sync="drawer.create.status" :ref="drawer.create.name"
      @opened="drawerOpened(drawer.create.component)" @closed="drawerClosed(drawer.create.component)">
      <crm-create :selected="selectedModel" :ref="drawer.create.component" :drawer-name="drawer.create.name"></crm-create>
    </el-drawer>
    <!-- end modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list, drawer],
  name: "patientHistories",
  components: {
    CrmCreate,
  },
  data() {
    return {
      loadingData: false,
      selectedModel: {},
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "hospitalPatients/list",
      columns: "patientHistories/columns",
      pagination: "hospitalPatients/pagination",
      filter: "hospitalPatients/filter",
      sort: "hospitalPatients/sort",
      hospitalPatient: "hospitalPatients/model",
      cities: "cities/inventory",
      regions: "regions/inventory",
      states: "states/inventory",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() {
    if (this.cities && this.cities.length === 0) {
      await this.getCityInventory();
    }
    if (this.regions && this.regions.length === 0) {
      await this.getRegionInventory();
    }
    if (this.states && this.states.length === 0) {
      this.getStateInventory();
    }
  },
  methods: {
    ...mapActions({
      updateList: "hospitalPatients/index",
      updateSort: "hospitalPatients/updateSort",
      updateFilter: "hospitalPatients/updateFilter",
      updateColumn: "patientHistories/updateColumn",
      updatePagination: "hospitalPatients/updatePagination",
      editModel: "hospitalPatients/show",
      empty: "hospitalPatients/empty",
      delete: "hospitalPatients/destroy",
      refreshData: "hospitalPatients/refreshData",
      getCityInventory: "cities/inventory",
      getRegionInventory: "regions/inventory",
      getStateInventory: "states/inventory",
    }),
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => { });
    },
    async show(id) {
      this.$router.push("/patientRoom/main/" + id);
    },
    openHospitalization(model) {
      this.selectedModel = model;
      this.drawer.create.status = true;
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Ошибка',
            type: "error",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
